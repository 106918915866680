import React from "react"
import Layout from "../layout/layout"
import { Helmet } from "react-helmet"
import config from "../../data/SiteConfig"

const ErrorPage = () => {
	return (
		<Layout>
			<Helmet>
				<title>{`404 - ${config.siteTitle}`}</title>
			</Helmet>
			<h1>Ups Nothing to see here</h1>
		</Layout>
	)
}

export default ErrorPage
